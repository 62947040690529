import { useGetConnectedUserQuery } from '../../api/authApi';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useUpdateUserMutation } from '../../api/usersApi';
import { toast } from 'react-toastify';
import consts from '../../consts/consts';
import Spinner from '../../components/Spinner';
import SuccessMessage from '../../components/SuccessMessage';

const successTitle = 'Schimbările pe care le-ați solicitat au fost efectuate cu succes.';

const ProfilePage = () => {
  const [cookies] = useCookies([consts.COOKIE_KEY_JWT]);
  const { data: user, isLoading, refetch: refetchUser } = useGetConnectedUserQuery(cookies[consts.COOKIE_KEY_JWT]);
  const [isChangePasswordChecked, setIsChangePasswordChecked] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const schema = yup.object().shape(
    {
      user_firstname: yup.string().when('user_company_name', {
        is: '',
        then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
      }),
      user_lastname: yup.string().when('user_company_name', {
        is: '',
        then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
      }),
      user_company_name: yup.string().when(['user_firstname', 'user_lastname'], {
        is: '',
        then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
      }),
      user_email: yup.string().email('Email invalid').required('Camp obligatoriu!'),
      user_phone: yup.string().required('Camp obligatoriu!'),
      user_communication_address: yup.string().required('Camp obligatoriu!'),
      ...(isChangePasswordChecked
        ? {
            user_password: yup.string().required('Camp obligatoriu'),
            user_new_password: yup.string().required('Camp obligatoriu'),
            user_new_password_confirmation: yup
              .string()
              .oneOf([yup.ref('user_new_password'), null], 'Parola nu se potriveste!')
              .required('Camp obligatoriu')
          }
        : {})
    },
    [
      ['user_lastname', 'user_company_name'],
      ['user_firstname', 'user_company_name']
    ]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ resolver: yupResolver(schema) });
  const [updateUser] = useUpdateUserMutation();

  async function onSubmit(data) {
    setIsSubmitLoading(true);

    try {
      data = { data, id: user.user_id };

      const { success = '', message = '' } = await updateUser(data, 1).unwrap();

      if (success) {
        setIsSubmitSuccess(true);
        await refetchUser();
      } else {
        toast.error(message);
      }
      setIsSubmitLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!isLoading && user) {
      if (!Boolean(+user.user_is_pf)) {
        setValue('user_company_name', user?.user_company_name);
      } else {
        setValue('user_firstname', user.user_firstname);
        setValue('user_lastname', user.user_lastname);
      }

      setValue('user_email', user.user_email);
      setValue('user_phone', user.user_phone);
      setValue('user_communication_address', user.user_communication_address);
    }
  }, [isLoading, user, setValue]);

  if (!isSubmitSuccess) {
    return (
      <>
        {!isLoading && user && (
          <>
            <h1 className='title self-start'>{`Profil, ${
              Boolean(+user?.user_is_pf) ? `${user.user_firstname} ${user.user_lastname}` : `${user.user_company_name}`
            }`}</h1>
            <form
              className='form md:w-[50%] bg-transparent shadow-none border-0 px-0 gap-2'
              onSubmit={handleSubmit(onSubmit)}
            >
              {!Boolean(+user?.user_is_pf) ? (
                <div className='formControl'>
                  <label className='formLabel'>Denumire societate:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_company_name && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_company_name')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_company_name?.message}</p>
                </div>
              ) : (
                <div className='flexBetween flex-1 gap-2 flex-wrap sm:flex-nowrap w-full'>
                  <div className='w-full sm:max-w-[50%]'>
                    <label className='formLabel'>Nume:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.user_firstname && 'border-[0.1px] border-primary-500'}`}
                      {...register('user_firstname')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_firstname?.message}</p>
                  </div>
                  <div className='w-full sm:max-w-[50%]'>
                    <label className='formLabel'>Prenume:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.user_lastname && 'border-[0.1px] border-primary-500'}`}
                      {...register('user_lastname')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_lastname?.message}</p>
                  </div>
                </div>
              )}
              <div className='flexBetween flex-1 gap-2 flex-wrap sm:flex-nowrap w-full'>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Email:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_email && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_email')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_email?.message}</p>
                </div>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Telefon:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_phone && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_phone')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_phone?.message}</p>
                </div>
              </div>
              <div className='formControl'>
                <label className='formLabel'>Adresa de comunicare:</label>
                <input
                  type='text'
                  className={`formInput ${errors?.user_communication_address && 'border-[0.1px] border-primary-500'}`}
                  {...register('user_communication_address')}
                />
                <p className='inputErrorMessage'>{errors?.user_communication_address?.message}</p>
              </div>
              <div className='formControl flex-row items-center justify-start gap-1'>
                <label className='formLabel text-base' htmlFor='checkbox-change-password'>
                  Modificare parola:
                </label>
                <input
                  id='checkbox-change-password'
                  type='checkbox'
                  checked={isChangePasswordChecked}
                  onChange={() => setIsChangePasswordChecked((prev) => !prev)}
                />
              </div>
              {isChangePasswordChecked && (
                <>
                  <div className='formControl'>
                    <label className='formLabel'>Parola:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.user_password && 'border-[0.1px] border-primary-500'}`}
                      {...register('user_password')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_password?.message}</p>
                  </div>
                  <div className='formControl'>
                    <label className='formLabel'>Parola noua:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.user_new_password && 'border-[0.1px] border-primary-500'}`}
                      {...register('user_new_password')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_new_password?.message}</p>
                  </div>
                  <div className='formControl'>
                    <label className='formLabel'>Confirmare parola noua:</label>
                    <input
                      type='text'
                      className={`formInput ${
                        errors?.user_new_password_confirmation && 'border-[0.1px] border-primary-500'
                      }`}
                      {...register('user_new_password_confirmation')}
                    />
                    <p className='inputErrorMessage'>{errors?.user_new_password_confirmation?.message}</p>
                  </div>
                </>
              )}
              <div className='flex items-center self-start'>
                <button type='submit' className='btn btn-dark self-start width-3/4 xs:max-w-fit mr-2'>
                  Salvare
                </button>
                {isSubmitLoading && <Spinner />}
              </div>
            </form>
          </>
        )}
      </>
    );
  } else {
    return <SuccessMessage title={successTitle} />;
  }
};

export default ProfilePage;
