import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import Modal from '@mui/material/Modal';
import { useMemo, useState } from 'react';
import { useGetUsersQuery, useActivateUser2Mutation } from '../../api/usersApi';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRef } from 'react';
import {
  useAddUserMutation,
  useVerifyUserMutation,
  useDiscardUserVerificationMutation,
  useUpdateUserMutation,
  useRemoveUserMutation,
  useGetNormalUsersQuery
} from '../../api/usersApi';
import DebitsList from '../../components/DebitsList';
import { useOutletContext } from 'react-router-dom';
import { FaUserCog, FaTrash } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import { getUsername, isCifValid, isCnpValid } from '../../utils/fn';

const UsersPage = () => {
  // queries
  const { data: users, isLoading: isUsersLoading } = useGetNormalUsersQuery();

  // mutations
  const [removeUser] = useRemoveUserMutation();
  const [activateUser2] = useActivateUser2Mutation();
  const [addUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [verifyUser] = useVerifyUserMutation();
  const [discardUserVerification] = useDiscardUserVerificationMutation();

  // modals states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  // user state action
  const [usersTypeFilter, setUsersTypeFilter] = useState('all');
  const [selectedId, setSelectedId] = useState(0);
  const [actionUserId, setActionUserId] = useState(0);
  const [verifiedUser, setVerifiedUser] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);

  const addUserFormRef = useRef(null);

  // get connected user
  const { connectedUser: user, isUserLoading } = useOutletContext();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        accessorFn: (row) =>
          !!+row.user_is_admin ? (
            <>
              <FaUserCog className='mr-1 text-primary-500' title='Admin' />
              {row.user_company_name?.length
                ? `${row.user_company_name}`
                : `${row.user_firstname} ${row.user_lastname}`}
            </>
          ) : (
            <>
              {row.user_company_name?.length
                ? `${row.user_company_name}`
                : `${row.user_firstname} ${row.user_lastname}`}
            </>
          ),
        header: 'Nume'
      },
      {
        accessorKey: 'user_cui_cnp',
        header: 'CUI/CNP',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
      },
      {
        accessorKey: 'user_email',
        header: 'Email',
        Cell: ({ cell }) => (
          <a href={`mailto:${cell.getValue()}`} className='link'>
            {cell.getValue()}
          </a>
        )
      },
      {
        accessorKey: 'user_phone',
        header: 'Mobil',
        Cell: ({ cell }) => (
          <a href={`tel:${cell.getValue()}`} className='link'>
            {cell.getValue()}
          </a>
        )
      },
      {
        accessorFn: (row) =>
          !!+row.user_is_activated_2 ? (
            <AiOutlineCheck className='text-green-500 text-xl font-extrabold' />
          ) : (
            <AiOutlineClose className='text-red-500 text-xl font-extrabold' />
          ),
        header: 'Activat',
        maxSize: 80
      }
    ],
    []
  );

  const selectedUser = useMemo(() => users?.find((user) => user.user_id === selectedId), [selectedId, users]);

  const schema = yup.object().shape(
    {
      user_cui_cnp: yup.number().typeError('Campul trebuie sa contina doar cifre!').required('Camp obligatoriu!'),
      user_firstname: yup.string().when('user_company_name', {
        is: '',
        then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
      }),
      user_lastname: yup.string().when('user_company_name', {
        is: '',
        then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
      }),
      user_company_name: yup.string().when(['user_firstname', 'user_lastname'], {
        is: '',
        then: yup.string('Campul trebuie sa contina doar litere!').required('Camp obligatoriu!')
      }),
      user_email: yup.string().email('Email invalid!').required('Camp obligatoriu'),
      user_email_confirmation: yup.string().oneOf([yup.ref('user_email'), null], 'Email-ul nu se potriveste!'),
      user_phone: yup.number().typeError('Campul trebuie sa contina doar cifre!').required('Camp obligatoriu'),
      user_communication_address: yup.string().required('Camp obligatoriu!')
    },
    [
      ['user_lastname', 'user_company_name'],
      ['user_firstname', 'user_company_name']
    ]
  );

  const filteredUsers = useMemo(() => {
    return (
      users?.filter((user) => {
        if (usersTypeFilter === 'active') {
          return Boolean(+user.user_is_activated_2);
        } else if (usersTypeFilter === 'inactive') {
          return !Boolean(+user.user_is_activated_2);
        } else {
          return true;
        }
      }) || []
    );
  }, [usersTypeFilter, users]);

  // form hook
  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  async function activateUserAccount2(userId) {
    try {
      const { success = false, message = '' } = await activateUser2(userId).unwrap();

      if (success) {
        toast.success(message);
        setIsModalOpen(false);
        setSelectedId(0);
        setActionUserId(userId);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addOrUpdateUser_Handler(e) {
    e.preventDefault();

    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    const formData = Object.fromEntries(new FormData(addUserFormRef.current).entries());

    delete formData.user_email_confirmation;

    formData.user_is_admin = false;

    if (!isCifValid(formData?.user_cui_cnp?.trim()) && !isCnpValid(formData?.user_cui_cnp?.trim())) {
      return setError('user_cui_cnp', { message: 'CUI/CNP invalid!' });
    }

    if (isCifValid(formData?.user_cui_cnp?.trim())) {
      formData.user_is_pf = true;
    } else if (isCnpValid(formData?.user_cui_cnp?.trim())) {
      formData.user_is_pf = false;
    }

    try {
      let result;

      if (userToUpdate) {
        result = await updateUser({ data: formData, id: userToUpdate.user_id }).unwrap();
      } else {
        formData.user_is_added_by_admin = true;
        result = await addUser(formData).unwrap();
      }

      const { success = false, id = 0, message = '' } = result;

      if (success) {
        message && toast.success(message);
        resetAddUpdateModalFields();
        !userToUpdate && setActionUserId(id);

        if (userToUpdate) {
          resetAddUpdateModalFields();
          setActionUserId(userToUpdate.user_id);
          setUserToUpdate(null);
        }

        setIsAddUserModalOpen(false);
      } else {
        message && toast.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function discardUserVerification_Handler(userId) {
    try {
      const body = { userId };
      await discardUserVerification(body).unwrap();
      setActionUserId(userId);
    } catch (error) {
      console.error(error);
    }
  }

  async function verifyUser_Handler(idCode, userId) {
    try {
      const body = { idCode, userId };
      const { user = null } = await verifyUser(body).unwrap();
      setVerifiedUser(user);
      console.log(verifiedUser);
      setIsInfoModalOpen(true);
      setActionUserId(userId);
    } catch (error) {
      console.error(error);
    }
  }

  function updateUserModal_Handler(e, user = null) {
    e.preventDefault();

    if (!user) return;

    setUserToUpdate(user);

    setIsAddUserModalOpen(true);

    setValue('user_cui_cnp', user.user_cui_cnp);
    setValue('user_firstname', user.user_firstname);
    setValue('user_lastname', user.user_lastname);
    setValue('user_company_name', user.user_company_name);
    setValue('user_email', user.user_email);
    setValue('user_email_confirmation', user.user_email);
    setValue('user_phone', user.user_phone);
    setValue('user_communication_address', user.user_communication_address);
    // setValue('user_password', user.user_password);
    // setValue('user_confirm_password', user.user_password);
  }

  function resetAddUpdateModalFields() {
    resetField('user_cui_cnp');
    resetField('user_firstname');
    resetField('user_lastname');
    resetField('user_company_name');
    resetField('user_email');
    resetField('user_email_confirmation');
    resetField('user_phone');
    resetField('user_communication_address');
  }

  function removeUserModal_Handler(e, userId) {
    e.preventDefault();
    setSelectedId(userId);
    setIsRemoveModalOpen(true);
  }

  async function removeUser_Handler(user) {
    if (!user) return;

    try {
      const { success = false, message = '' } = await removeUser(user.user_id).unwrap();

      if (success) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setActionUserId(user?.user_id || 0);
      setSelectedId(0);
      setIsRemoveModalOpen(false);
    }
  }

  return (
    <div className='flex flex-col gap-4'>
      {/* modal for user activation */}
      <Modal open={isModalOpen} onClose={(e) => setIsModalOpen(false)}>
        <div className='modalContainer'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>
              {!!+selectedUser?.user_is_activated_2 ? 'Dezactivare' : 'Activare'} cont utilizator
            </h1>
            <button className='text-gray-100 hover:text-white text-xl' onClick={(e) => setIsModalOpen(false)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <span className=''>
              Contul utilizatorului{' '}
              <strong>
                {selectedUser?.user_firstname} {selectedUser?.user_lastname}
              </strong>{' '}
              va fi <strong>{!!+selectedUser?.user_is_activated_2 ? 'dezactivat' : 'activat'}</strong>, sunteti sigur?
            </span>
          </div>
          <div className='modalFooter'>
            <button className='btn btn-primary' onClick={(e) => activateUserAccount2(selectedId)}>
              Ok
            </button>
            <button className='btn btn-dark' onClick={(e) => setIsModalOpen(false)}>
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      {/* modal for adding a user */}
      <Modal
        open={isAddUserModalOpen}
        onClose={(e) => {
          resetAddUpdateModalFields();
          setUserToUpdate(null);
          setIsAddUserModalOpen(false);
        }}
      >
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>{userToUpdate ? 'Modificare utilizator' : 'Adaugare utilizator'}</h1>
            <button
              className='text-gray-100 hover:text-white text-xl'
              onClick={(e) => {
                setUserToUpdate(null);
                setIsAddUserModalOpen(false);
                resetAddUpdateModalFields();
              }}
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <form ref={addUserFormRef} className='w-full flex flex-col'>
              <div className='formControl'>
                <label className='formLabel'>CUI/CNP:</label>
                <input
                  type='text'
                  className={`formInput ${errors?.user_cui_cnp && 'border-[0.1px] border-primary-500'}`}
                  {...register('user_cui_cnp')}
                />
                <p className='inputErrorMessage'>{errors?.user_cui_cnp?.message}</p>
              </div>
              <div className='flexBetween flex-1 gap-1 xs:gap-2 flex-wrap xs:flex-nowrap w-full'>
                <div className='flex-grow w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Nume:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_firstname && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_firstname')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_firstname?.message}</p>
                </div>
                <div className='flex-grow w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Prenume:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_lastname && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_lastname')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_lastname?.message}</p>
                </div>
              </div>
              <div className='formControl'>
                <label className='formLabel'>Denumire companie:</label>
                <input
                  type='text'
                  className={`formInput ${errors?.user_company_name && 'border-[0.1px] border-primary-500'}`}
                  {...register('user_company_name')}
                />
                <p className='inputErrorMessage'>{errors?.user_company_name?.message}</p>
              </div>
              <div className='flexBetween flex-1 gap-1 xs:gap-2 flex-wrap xs:flex-nowrap w-full'>
                <div className='flex-grow w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Email:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_email && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_email')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_email?.message}</p>
                </div>
                <div className='flex-grow w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Confirmare email:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_email_confirmation && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_email_confirmation')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_email_confirmation?.message}</p>
                </div>
              </div>
              <div className='flexBetween flex-1 gap-1 xs:gap-2 flex-wrap xs:flex-nowrap w-full'>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Telefon:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_phone && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_phone')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_phone?.message}</p>
                </div>
                <div className='w-full sm:max-w-[50%]'>
                  <label className='formLabel'>Adresa de comunicare:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.user_communication_address && 'border-[0.1px] border-primary-500'}`}
                    {...register('user_communication_address')}
                  />
                  <p className='inputErrorMessage'>{errors?.user_communication_address?.message}</p>
                </div>
              </div>
              {/* <div className='formControl flex-row items-center justify-start gap-1'>
                <label className='formLabel text-base' htmlFor='checkbox-user-is-admin'>
                  Este admin:
                </label>
                <input type='checkbox' id='checkbox-user-is-admin' {...register('user_is_admin')} />
              </div> */}
            </form>
          </div>
          <div className='modalFooter'>
            <button
              onClick={(e) => addOrUpdateUser_Handler(e)}
              type='submit'
              className='btn btn-primary self-start w-full xs:max-w-fit'
            >
              {userToUpdate ? 'Modificare' : 'Adaugare'}
            </button>
            <button
              className='btn btn-dark'
              onClick={() => {
                setUserToUpdate(null);
                setIsAddUserModalOpen(false);
                resetAddUpdateModalFields();
              }}
            >
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      {/* modal for user verification */}
      <Modal open={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)}>
        <div className='modalContainer md:max-w-[800px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Verificare utilizator</h1>
            <button className='text-gray-100 hover:text-white text-xl' onClick={() => setIsInfoModalOpen(false)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            {verifiedUser ? (
              <div className='flex flex-col gap-2'>
                <p>
                  Utilizatorul <strong>{verifiedUser.omulon_numpre}</strong> a fost identificat!
                </p>
                <DebitsList roles={verifiedUser?.roles || []} />
              </div>
            ) : (
              <p>Utilizatorul nu a fost identificat (nu a fost gasit in datele pentru roluri)!</p>
            )}
            <div></div>
          </div>
          <div className='modalFooter'>
            <button className='btn btn-dark' onClick={() => setIsInfoModalOpen(false)}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
      {/* modal for removing the user */}
      <Modal open={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)}>
        <div className='modalContainer md:max-w-[600px]'>
          <div className='modalHeader'>
            <h1 className='subTitle text-white'>Eliminare utilizator</h1>
            <button className='text-gray-100 hover:text-white text-xl' onClick={() => setIsRemoveModalOpen(false)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='modalBody'>
            <p>
              Utilizatorul <strong className='uppercase'>{getUsername(selectedUser)}</strong> va fi eliminat, sunteti
              sigur?
            </p>
          </div>
          <div className='modalFooter'>
            <button className='btn btn-primary' onClick={() => removeUser_Handler(selectedUser)}>
              Da
            </button>
            <button className='btn btn-dark' onClick={() => setIsRemoveModalOpen(false)}>
              Anulare
            </button>
          </div>
        </div>
      </Modal>
      <h1 className='title'>Utilizatori</h1>
      <div className='w-full'>
        <MaterialReactTable
          columns={columns}
          data={filteredUsers || []}
          // enableRowSelection
          getRowId={(row) => row.user_id}
          // onRowSelectionChange={setRowSelection}
          // state={{ rowSelection, isLoading }}
          state={{ isUsersLoading }}
          enableStickyHeader
          enableStickyFooter
          enableColumnResizing
          localization={MRT_Localization_RO}
          initialState={{
            showGlobalFilter: true,
            density: 'compact',
            columnPinning: { right: ['actions'] }
          }}
          enablePinning
          defaultColumn={{
            maxSize: 400,
            minSize: 80,
            size: 150 //default size is usually 180
          }}
          enableDensityToggle={false}
          layoutMode='grid'
          enableRowActions
          muiTableBodyCellProps={{
            sx: {
              whiteSpace: 'pre-line'
            }
          }}
          renderRowActions={({ row: { original: data } }, index) =>
            !isUserLoading && user?.user_id !== data.user_id ? (
              <div className='flex gap-1'>
                {!!+data.user_is_verified ? (
                  <>
                    <button
                      onClick={(e) => activateUserAccount2(data.user_id)}
                      className='btn btn-outline btn-xm hover:scale-[0.99] px-1 py-[0.1px]'
                    >
                      {!!+data.user_is_activated_2 ? 'Dezactivare' : 'Activare'}
                    </button>
                    <button
                      onClick={(e) => discardUserVerification_Handler(data.user_id)}
                      className='btn btn-outline btn-xm hover:scale-[0.99] px-1 py-[0.1px]'
                    >
                      Anulare
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) => verifyUser_Handler(data.user_cui_cnp, data.user_id)}
                    className='btn btn-outline btn-xm hover:scale-[0.99] px-1 py-[0.1px]'
                  >
                    Verificare
                  </button>
                )}
                {!Boolean(+data.user_is_verified) && (
                  <button
                    className='btn btn-outline btn-xm hover:scale-[0.99] px-1 py-[0.1px]'
                    onClick={(e) => updateUserModal_Handler(e, data)}
                  >
                    <AiFillEdit />
                  </button>
                )}
                {!Boolean(+data.user_is_activated_2) && (
                  <button
                    className='btn btn-outline btn-xm hover:scale-[0.99] px-1 py-[0.1px]'
                    onClick={(e) => removeUserModal_Handler(e, data.user_id)}
                  >
                    <FaTrash />
                  </button>
                )}
              </div>
            ) : (
              ''
            )
          }
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: '', //change header text
              minSize: 142,
              maxSize: 300
            }
          }}
          muiTableBodyRowProps={({ row: { original: user } }) => ({
            sx: {
              ...(user.user_id === actionUserId && { background: '#fbd6d3' })
            },
            hover: false
          })}
          positionActionsColumn='last'
          muiTableHeadCellProps={{
            sx: {
              // background: "#475569",
              color: 'black',
              fontWeight: 'bold'
            }
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: '500px'
            }
          }}
          muiTableBodyProps={{
            sx: {
              '& tr:nth-of-type(odd)': {
                // backgroundColor: "#f5f5f5",
              }
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '0',
              border: '0.1px solid #e0e0e0',
              boxShadow: 'none'
            }
          }}
          muiTableProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              boxShadow: 'none'
            }
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <div className='flex gap-4'>
              <button className='btn btn-dark' onClick={(e) => setIsAddUserModalOpen(true)}>
                Adaugare
              </button>
              <div className='flex gap-2'>
                {/* <label className='formLabel'>Tip:</label> */}
                <select
                  className='formInput w-[150px]'
                  onChange={(e) => setUsersTypeFilter(e.target.value)}
                  defaultValue={0}
                >
                  <option value='all'>Toti</option>
                  <option value='active'>Activi</option>
                  <option value='inactive'>Inactivi</option>
                </select>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default UsersPage;
